import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const fetchBlogs = createAsyncThunk(
  "blogs/fetchBlogs",
  async (locale = "en") => {
    const response = await axios.get(
      `blogs?populate=img&locale=${locale}&sort[0]=createdAt:desc`,
    );
    return response.data;
  },
);

const initialState = {
  blogs: [],
  status: "idle",
  error: null,
};

const blogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.blogs = action.payload;
      })
      .addCase(fetchBlogs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default blogsSlice.reducer;

export const selectAllBlogs = (state) => state.blogs.blogs;
