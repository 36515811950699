"use client";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const fetchTopHospitals = createAsyncThunk(
  "topHospitals/fetchTopHospitals",
  async (params = {}) => {
    const { locale = "en", filters = {}, pagination = { page: 1, pageSize: 10 } } = params;
    const queryParams = new URLSearchParams();

    // Add base params
    queryParams.append('populate', 'img');
    queryParams.append('locale', locale);

    // Add pagination in Strapi format
    queryParams.append('pagination[page]', pagination.page);
    queryParams.append('pagination[pageSize]', pagination.pageSize);

    // Add filters if they exist
    if (filters.country) {
      queryParams.append('filters[country][$contains]', filters.country);
    }

    const queryString = `top-hospitals?${queryParams.toString()}`;
    const response = await axios.get(queryString);
    return response.data;
  }
);

const topHospitalsSlice = createSlice({
  name: "topHospitals",
  initialState: {
    hospitals: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopHospitals.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTopHospitals.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.hospitals = action.payload;
      })
      .addCase(fetchTopHospitals.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default topHospitalsSlice.reducer;

export const selectAllTopHospitals = (state) => state.top_hospitals.hospitals;
