import React, { Suspense, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { SkeletonTheme } from "react-loading-skeleton";
import { Toaster } from "react-hot-toast";

// Dynamic import for IntlProvider
import { IntlProvider } from "react-intl";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import instance from "@/utils/axios";

const LanguageWrapper = ({ children }) => {
  const lang = useSelector((state) => state.locale.locale);
  const [messages, setMessages] = useState(null);
  const [navbarFooterData, setNavbarFooterData] = useState(null);
  console.log(navbarFooterData,"navbarFooterData")

  useEffect(() => {
    const fetchNavbarFooterData = async () => {
      try {
        const response = await instance.get('/footer-navbar-section-info', {
          params: {
            populate: [
              'social_media_links',
              'social_media_links.icon',
            ]
          }
        });
        setNavbarFooterData(response.data);
      } catch (error) {
        console.error('Error fetching navbar/footer data:', error);
      }
    };

    fetchNavbarFooterData();
  }, []);

  useEffect(() => {
    const loadLocaleData = async () => {
      switch (lang) {
        case "en":
          setMessages(await import("../components/locales/en/common.json"));
          break;
        case "ar":
          setMessages(await import("../components/locales/ar/common.json"));
          break;
        case "fr":
          setMessages(await import("../components/locales/fr/common.json"));
          break;
        case "ru":
          setMessages(await import("../components/locales/ru/common.json"));
          break;
        case "tl":
          setMessages(await import("../components/locales/tl/common.json"));
          break;
        case "id":
          setMessages(await import("../components/locales/id/common.json"));
          break;
        case "ms":
          setMessages(await import("../components/locales/ms/common.json"));
          break;
        case "bn":
          setMessages(await import("../components/locales/bn/common.json"));
          break;
        case "am":
          setMessages(await import("../components/locales/am/common.json"));
          break;
        case "kz":
          setMessages(await import("../components/locales/kz/common.json"));
          break;
        case "uz":
          setMessages(await import("../components/locales/uz/common.json"));
          break;
        case "tz":
          setMessages(await import("../components/locales/tz/common.json"));
          break;
        case "ha":
          setMessages(await import("../components/locales/ha/common.json"));
          break;
        case "ja":
          setMessages(await import("../components/locales/ja/common.json"));
          break;
        case "es":
          setMessages(await import("../components/locales/es/common.json"));
          break;
        case "pt":
          setMessages(await import("../components/locales/pt/common.json"));
          break;
        default:
          setMessages(await import("../components/locales/en/common.json"));
      }
    };

    loadLocaleData();
  }, [lang]);

  if (!messages) {
    return;
  }

  return (
    <div>
      <IntlProvider locale={lang} messages={messages}>
        {/* Dynamically loaded Navbar */}
        <Suspense fallback={<div>Loading Navbar...</div>}>
          <Navbar data={navbarFooterData}/>
        </Suspense>

        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <div>{children}</div>
        </SkeletonTheme>

        {/* Toaster for notifications */}
        <Toaster />

        {/* Dynamically loaded Footer */}
        <Suspense fallback={<div>Loading Footer...</div>}>
          <Footer data={navbarFooterData}/>
        </Suspense>
      </IntlProvider>
    </div>
  );
};

export default LanguageWrapper;
