import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locale: "en",
};

export const localeSlice = createSlice({
  name: "locale",
  initialState,
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload;
    },
  },
});

export const { setLocale } = localeSlice.actions;

export const selectLocale = (state) => state.locale.locale;

export default localeSlice.reducer;
