"use client";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const fetchTopDoctors = createAsyncThunk(
  "topDoctors/fetchTopDoctors",
  async ({ filters = {}, locale = "en", page = 1, pageSize = 10 } = {}) => {
    let queryString = `top-doctors?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}`;

    if (locale) {
      queryString += `&locale=${locale}`;
    }

    if (Object.keys(filters).length > 0) {
      queryString +=
        "&" +
        Object.entries(filters)
          .map(([key, value]) => {
            if (typeof value === "object") {
              return Object.entries(value)
                .map(
                  ([operator, operand]) =>
                    `filters[${key}][${operator}]=${operand}`,
                )
                .join("&");
            } else {
              return `filters[${key}]=${value}`;
            }
          })
          .join("&");
    }

    try {
      const response = await axios.get(queryString);
      return response.data;
    } catch (error) {
      console.error("Error fetching top doctors:", error);
      throw error;
    }
  },
);

const initialState = {
  doctors: [],
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    pageCount: 0,
  },
  status: "",
  error: null,
  hasMore: true,
};

const topDoctorsSlice = createSlice({
  name: "topDoctors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopDoctors.pending, (state) => {
        state.status = true;
      })
      .addCase(fetchTopDoctors.fulfilled, (state, action) => {
        state.status = false;
        // Append new doctors to existing ones for infinite scroll
        state.doctors =
          state.pagination.page === 1
            ? action.payload.data
            : [...state.doctors, ...action.payload.data];
        state.pagination = action.payload.meta.pagination;
        state.hasMore = state.pagination.page < state.pagination.pageCount;
      })
      .addCase(fetchTopDoctors.rejected, (state, action) => {
        state.status = false;
        state.error = action.error.message;
      });
  },
});

export default topDoctorsSlice.reducer;

export const selectAllTopDoctors = (state) => state.top_doctors.doctors;
export const selectTopDoctorsPagination = (state) =>
  state.top_doctors.pagination;
export const selectHasMore = (state) => state.top_doctors.hasMore;
