"use client";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const fetchTermsOfUses = createAsyncThunk(
  "termsOfUses/fetchTermsOfUses",
  async () => {
    const response = await axios.get("terms-of-uses");
    return response.data;
  },
);

const termsOfUsesSlice = createSlice({
  name: "termsOfUses",
  initialState: {
    terms: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTermsOfUses.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTermsOfUses.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.terms = action.payload;
      })
      .addCase(fetchTermsOfUses.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default termsOfUsesSlice.reducer;

export const selectAllTermsOfUses = (state) => state.termsOfUses.terms;
