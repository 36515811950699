"use client";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const fetchTreatmentBanners = createAsyncThunk(
  "treatmentBanners/fetchTreatmentBanners",
  async (locale) => {
    const response = await axios.get(
      `treatment-banners?populate=img&locale=${locale}`,
    );
    return response.data;
  },
);

const initialState = {
  banners: [],
  status: "idle",
  error: null,
};

const treatmentBannersSlice = createSlice({
  name: "treatmentBanners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTreatmentBanners.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTreatmentBanners.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.banners = action.payload;
      })
      .addCase(fetchTreatmentBanners.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default treatmentBannersSlice.reducer;

export const selectAllTreatmentsBanners = (state) =>
  state.treatments_banner.banners;
