"use client";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const fetchTreatmentDetails = createAsyncThunk(
  "treatmentDetails/fetchTreatmentDetails",
  async (slug) => {
    const response = await axios.get(`treatments`, {
      params: {
        filters: { slug: { $eq: slug } },
        populate:
          "treatment_lists,top_doctors,top_doctors.profile,top_hospitals,top_hospitals.img,seo,seo.metaImage,seo.metaSocial.image",
      },
    });
    return response.data;
  },
);

const initialState = {
  data: null,
  status: "idle",
  error: null,
};

const treatmentDetailsSlice = createSlice({
  name: "treatmentDetails",
  initialState,
  reducers: {
    clearState: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTreatmentDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTreatmentDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchTreatmentDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { clearState } = treatmentDetailsSlice.actions;

export default treatmentDetailsSlice.reducer;
