import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const fetchContactUs = createAsyncThunk(
  "contactUs/fetchContactUs",
  async () => {
    const response = await axios.get("contact-us");
    return response.data;
  },
);

const initialState = {
  data: null,
  status: "idle",
  error: null,
};

const contactUsSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContactUs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchContactUs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchContactUs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default contactUsSlice.reducer;

export const selectContactUsData = (state) => state.contactUs.data;
