"use client";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const fetchCountries = createAsyncThunk(
  "data/fetchCountries",
  async () => {
    const response = await axios.get("countries");
    return response.data;
  },
);

export const fetchCities = createAsyncThunk("data/fetchCities", async () => {
  const response = await axios.get("cities");
  return response.data;
});

const dataSlice = createSlice({
  name: "data",
  initialState: {
    countries: [],
    cities: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.countries = action.payload;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchCities.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cities = action.payload;
      })
      .addCase(fetchCities.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default dataSlice.reducer;

export const selectAllCountries = (state) => state.data.countries;
export const selectAllCities = (state) => state.data.cities;
