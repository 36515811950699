"use client";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const searchTopDoctors = createAsyncThunk(
  "search/searchTopDoctors",
  async (query) => {
    const response = await axios.get(`top-doctors?_q=${query}`);
    return response.data;
  },
);

export const searchTopHospitals = createAsyncThunk(
  "search/searchTopHospitals",
  async (query) => {
    const response = await axios.get(`top-hospitals?_q=${query}`);
    return response.data;
  },
);

export const searchTreatments = createAsyncThunk(
  "search/searchTreatments",
  async (query) => {
    const response = await axios.get(`treatments?_q=${query}`);
    return response.data;
  },
);

const initialState = {
  doctors: {
    data: [],
    status: "idle",
    error: null,
  },
  hospitals: {
    data: [],
    status: "idle",
    error: null,
  },
  treatments: {
    data: [],
    status: "idle",
    error: null,
  },
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchTopDoctors.pending, (state) => {
        state.doctors.status = "loading";
      })
      .addCase(searchTopDoctors.fulfilled, (state, action) => {
        state.doctors.status = "succeeded";
        state.doctors.data = action.payload.data;
      })
      .addCase(searchTopDoctors.rejected, (state, action) => {
        state.doctors.status = "failed";
        state.doctors.error = action.error.message;
      });

    builder
      .addCase(searchTopHospitals.pending, (state) => {
        state.hospitals.status = "loading";
      })
      .addCase(searchTopHospitals.fulfilled, (state, action) => {
        state.hospitals.status = "succeeded";
        state.hospitals.data = action.payload.data;
      })
      .addCase(searchTopHospitals.rejected, (state, action) => {
        state.hospitals.status = "failed";
        state.hospitals.error = action.error.message;
      });

    builder
      .addCase(searchTreatments.pending, (state) => {
        state.treatments.status = "loading";
      })
      .addCase(searchTreatments.fulfilled, (state, action) => {
        state.treatments.status = "succeeded";
        state.treatments.data = action.payload.data;
      })
      .addCase(searchTreatments.rejected, (state, action) => {
        state.treatments.status = "failed";
        state.treatments.error = action.error.message;
      });
  },
});

export default searchSlice.reducer;
