import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

// Thunk to fetch fitness blogs
export const fetchFitnessBlogs = createAsyncThunk(
  "fitnessBlogs/fetchFitnessBlogs",
  async (locale = "en") => {
    const response = await axios.get(
      `fitness-blogs?populate=img&locale=${locale}&sort[0]=createdAt:desc`,
    );
    return response.data;
  },
);

const fitnessBlogsSlice = createSlice({
  name: "fitnessBlogs",
  initialState: {
    blogs: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFitnessBlogs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFitnessBlogs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.blogs = action.payload;
      })
      .addCase(fetchFitnessBlogs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectAllFitnessBlogs = (state) => state.fitnessBlogs.blogs;

export default fitnessBlogsSlice.reducer;
