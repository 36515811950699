// import Lottie from 'lottie-react';
import loadingAnimation from "../animation/loading.json";
const FullScreenLoader = ({ isLoading }) => {
  return (
    isLoading && (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.5)", // Transparent background
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999, // High z-index to cover everything
        }}
      >
        <div className="w-80 loader">
          {/* <Lottie animationData={loadingAnimation} loop={true} /> */}
        </div>
      </div>
    )
  );
};

export default FullScreenLoader;
