"use client";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const fetchTreatmentPackageDetailsBySlug = createAsyncThunk(
  "treatmentPackageDetails/fetchTreatmentPackageDetailsBySlug",
  async ({ slug, locale = "en" }) => {
    const queryString = `treatment-packages?populate=*&locale=${locale}&filter[$eq][slug]=${slug}`;
    const response = await axios.get(queryString);
    return response.data;
  },
);

const initialState = {
  treatmentPackageDetails: null,
  status: "idle",
  error: null,
};

const treatmentPackageDetailsSlice = createSlice({
  name: "treatmentPackageDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTreatmentPackageDetailsBySlug.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchTreatmentPackageDetailsBySlug.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          state.treatmentPackageDetails = action.payload;
        },
      )
      .addCase(fetchTreatmentPackageDetailsBySlug.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default treatmentPackageDetailsSlice.reducer;

export const selectTreatmentPackageDetails = (state) =>
  state.treatmentPackageDetails.treatmentPackageDetails;
