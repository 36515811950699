import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const fetchExpertDetails = createAsyncThunk(
  "expertDetails/fetchExpertDetails",
  async ({ slug }) => {
    const queryString = `experts?populate=*&filters[slug]=${slug}`;
    const response = await axios.get(queryString);
    return response.data;
  },
);

const initialState = {
  expert: null,
  status: "idle",
  error: null,
};

const expertDetailsSlice = createSlice({
  name: "expertDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpertDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchExpertDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.expert = action.payload;
      })
      .addCase(fetchExpertDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default expertDetailsSlice.reducer;

export const selectExpertDetails = (state) => state.expertDetails.expert;
