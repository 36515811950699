"use client";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const fetchTreatmentPackages = createAsyncThunk(
  "treatmentPackages/fetchTreatmentPackages",
  async (params = {}) => {
    const {
      locale = "en",
      filters = {},
      pagination = { page: 1, pageSize: 10 }
    } = params;

    const queryParams = new URLSearchParams();

    // Add base params
    queryParams.append('populate', '*');
    queryParams.append('locale', locale);

    // Add pagination
    queryParams.append('pagination[page]', pagination.page);
    queryParams.append('pagination[pageSize]', pagination.pageSize);

    // Add filters if they exist
    if (filters.country) {
      queryParams.append('filters[country][$contains]', filters.country);
    }

    const queryString = `treatment-packages?${queryParams.toString()}`;
    const response = await axios.get(queryString);
    return response.data;
  }
);

// Function to create filter query string
function createFilterQueryString(filters) {
  let queryString = "";
  for (const key in filters) {
    const value = filters[key];
    if (typeof value === "object") {
      // If value is an object, assume it contains comparison operators
      for (const op in value) {
        queryString += `filters[${key}][${op}]=${value[op]}&`;
      }
    } else {
      // If value is not an object, assume it's a direct value
      queryString += `filters[${key}]=${value}&`;
    }
  }
  // Remove the trailing '&' if present
  queryString = queryString.replace(/&$/, "");
  return queryString;
}

const initialState = {
  packages: [],
  status: "idle",
  error: null,
};

const treatmentPackagesSlice = createSlice({
  name: "treatmentPackages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTreatmentPackages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTreatmentPackages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.packages = action.payload;
      })
      .addCase(fetchTreatmentPackages.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default treatmentPackagesSlice.reducer;

export const selectAllTreatmentPackages = (state) =>
  state.treatmentPackage.packages;

export const selectAllTreatmentPackagesStatus = (state) =>
  state.treatmentPackage.status;
