"use client";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const fetchPrivacyPolicies = createAsyncThunk(
  "privacyPolicies/fetchPrivacyPolicies",
  async () => {
    const response = await axios.get("privacy-policies");
    return response.data;
  },
);

const privacyPoliciesSlice = createSlice({
  name: "privacyPolicies",
  initialState: {
    policies: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrivacyPolicies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPrivacyPolicies.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.policies = action.payload;
      })
      .addCase(fetchPrivacyPolicies.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default privacyPoliciesSlice.reducer;

export const selectAllPrivacyPolicies = (state) =>
  state.privacyPolicies.policies;
