"use client";
import "./navbar.scss";
import Link from "next/link";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { navLinks } from "@/utils/data";
import { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import { Input } from "../ui/input";
import { PhoneNumberUtil } from "google-libphonenumber";
import { useIntl } from "react-intl";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "../ui/label";
import { useDispatch, useSelector } from "react-redux";
import { postPartnersLead } from "@/slice/partnerleadSlice";
import { fetchTreatments } from "@/slice/treatmentsSlice";
import { Button } from "../ui/button";
import FullScreenLoader from "@/app/components/FullScreenLoader/FullScreenLoader";
import { country, language } from "../../../constant/country";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import toast from "react-hot-toast";
import { setLocale } from "@/slice/localeSlice";
import { createPartnerLeadInZoho, createZohoLead } from "@/action/zoho";

export default function Navbar({ data }) {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const treatments = useSelector((state) => state.treatments.treatments.data);
  const dispatch = useDispatch();
  const status = useSelector((state) => state.postPartnersLead.status);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [parter_type, setparter_type] = useState("");
  const [selectedTreatment, setSelectedTreatment] = useState("");

  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };
  const [selectedcountry, setSelectedCountry] = useState({
    name: "India",
    flag: "🇮🇳",
    code: "IN",
    dial_code: "+91",
  });
  const [phoneNumber, setPhoneNumber] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const pathName = usePathname();

  const isCurrent = () => {
    const segments = pathName.split("/");
    if (
      segments[1] === "fitness" ||
      segments[1] === "fitness-blogs" ||
      segments[1] === "dietitian-experts" ||
      segments[1] === "yoga-experts" ||
      segments[1] === "experts"
    ) {
      return "/fitness";
    }
    return "/";
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {
    let consultationData = {
      name: name,
      mobile: phoneNumber,
      country: selectedcountry.name,
      email,
      parter_type: [parter_type],
    };
    if (selectedTreatment) {
      consultationData.treatment = selectedTreatment;
    }

    if (!isPhoneValid(selectedcountry.dial_code + phoneNumber)) {
      return toast.error("Invalid Phone Number");
    }

    await createPartnerLeadInZoho(consultationData);

    setIsModalOpen(false);
  };

  const lang = useSelector((state) => state.locale.locale);
  useEffect(() => {
    dispatch(fetchTreatments(lang));
  }, [lang]);

  const selected_language = language.find((i) => i.sort_name == lang);
  const intl = useIntl();

  const handleLocaleChange = (newLocale) => {
    dispatch(setLocale(newLocale));
  };

  const navbarData = data?.data || {};

  return (
    <div className="navbarWrapper">
      <div className="navbar">
        <div className="logoContainer">
          <Link href="/">
            <Image
              loading="lazy"
              src="/images/fitsibIcon.svg"
              fill
              alt="fitsib logo"
              className="w-[170px] h-[60px]"
              // 170 × 60
            />
          </Link>
        </div>

        <ul className="navLinksContainer">
          {navLinks.map((link) => {
            return (
              <li key={link.url}>
                <Link
                  className={`links ${
                    isCurrent() === link.url ? "active" : ""
                  }`}
                  href={link.url}
                >
                  {intl.formatMessage({ id: link.name })}
                </Link>
              </li>
            );
          })}
        </ul>
        <p className="whatsAppIcon">
          <Link
            href={`https://api.whatsapp.com/send?phone=${navbarData.whatsapp_number}&text=Hello,%20please%20contact%20me%20regarding%20https://fitsib.com/,%20Thank%20you!`}
          >
            <Image
              loading="lazy"
              src="/images/WhatsApp_icon.png"
              alt="WhatsApp_icon"
              width={30}
              height={30}
            />
          </Link>
        </p>

        <p className="whatsAppIcon">
          <Link href={`https://t.me/${navbarData.telegram_username}`}>
            <Image
              loading="lazy"
              src="/images/WhatsApp_icon_2.png"
              alt="Telegram_icon"
              width={24}
              height={24}
            />
          </Link>
        </p>
        <a href="/become-partner" className="becomePartnerHeading">
          {/* <p className="becomePartnerHeading" > */}
          {intl.formatMessage({ id: "BECOME_PARTNER" })}
          {/* </p> */}
        </a>
        <div className="flex items-center space-x-2" style={{ order: 4 }}>
          <Select onValueChange={(lang) => handleLocaleChange(lang.sort_name)}>
            <SelectTrigger className="w-full">
              <SelectValue
                className="placeholder:text-[#9AA3B7] opacity-10"
                placeholder={
                  <div className="flex flex-row items-center gap-4">
                    <Image
                      loading="lazy"
                      src={selected_language.flag}
                      className="w-4 h-4"
                      alt="flag"
                      width={16}
                      height={16}
                    />
                    <p className="hidden sm:flex"> {selected_language.name}</p>
                  </div>
                }
              />
            </SelectTrigger>
            <SelectContent defaultValue={selected_language}>
              {language &&
                language.map((lang) => (
                  <SelectItem
                    key={lang.name}
                    value={lang}
                    onChange={() => {
                      handleLocaleChange(lang.sort_name);
                    }}
                  >
                    <div className="flex flex-row items-center gap-4">
                      <Image
                        loading="lazy"
                        src={lang.flag}
                        className="w-4 h-4"
                        width={16}
                        height={16}
                        alt="flag"
                      />
                      <p className="hidden sm:flex"> {lang.name}</p>
                    </div>
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        </div>
        <Modal showModal={isModalOpen} closeModal={closeModal}>
          <div className="p-10 w-full flex flex-col gap-5 sm:min-w-[600px]">
            <RadioGroup
              className="flex flex-row"
              defaultValue="partner"
              onValueChange={(val) => setparter_type(val)}
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="partner" id="r1" />
                <Label htmlFor="r1">
                  {intl.formatMessage({ id: "partner" })}
                </Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="doctor" id="r2" />
                <Label htmlFor="r2">
                  {intl.formatMessage({ id: "doctor" })}
                </Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="hospital" id="r3" />
                <Label htmlFor="r3">
                  {intl.formatMessage({ id: "hospital" })}
                </Label>
              </div>
            </RadioGroup>
            <Input
              className="w-full"
              placeholder={intl.formatMessage({
                id: "enter_name",
              })}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <Input
              className="w-full"
              placeholder={intl.formatMessage({
                id: "enter_email",
              })}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            {parter_type == "doctor" && (
              <div className="w-full">
                <Label>
                  {intl.formatMessage({
                    id: "treatment",
                  })}
                </Label>
                <select
                  className="w-full border rounded-md p-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  onChange={(e) => setSelectedTreatment(e.target.value)}
                >
                  <option value="" disabled selected>
                    {intl.formatMessage({
                      id: "select_treatment",
                    })}
                  </option>
                  {treatments &&
                    treatments.map((treatment) => (
                      <option key={treatment.id} value={treatment.id}>
                        {treatment.name}
                      </option>
                    ))}
                </select>
              </div>
            )}

            <div className="w-full">
              <Label>
                {intl.formatMessage({
                  id: "country",
                })}
              </Label>
              <select
                className="w-full border rounded-md p-2 mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onChange={(e) => setSelectedCountry(JSON.parse(e.target.value))}
              >
                <option value="" disabled selected>
                  {intl.formatMessage({
                    id: "select_country",
                  })}
                </option>
                {country &&
                  country.map((country) => (
                    <option key={country.name} value={JSON.stringify(country)}>
                      {country.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="w-full h-10 ">
              <Label>
                {intl.formatMessage({
                  id: "mobile",
                })}
              </Label>
              <div className="flex w-full h-10 text-sm border rounded-md bg-background ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 p-2.5">
                <div className="flex flex-row items-center gap-4 ml-2 bg-white">
                  <span className="flex flex-col gap-2 text-sm">
                    {selectedcountry.flag}
                  </span>
                  {selectedcountry.dial_code}
                  <Input
                    placeholder={intl.formatMessage({
                      id: "phone_number_placeholder",
                    })}
                    className="h-8 border-none outline-none focus-visible:ring-0"
                    value={phoneNumber}
                    onChange={(e) => {
                      if (e.target.value.length > 10) return;
                      setPhoneNumber(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <Button className="mt-[24px]" onClick={handleSubmit}>
              {intl.formatMessage({
                id: "chat_now",
              })}
            </Button>
          </div>
        </Modal>

        <FullScreenLoader isLoading={status == "loading"} />
      </div>
    </div>
  );
}
