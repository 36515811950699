"use client";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const fetchTopDoctors = createAsyncThunk(
  "topDoctors/fetchTopDoctors",
  async (slug) => {
    const response = await axios.get(`top-doctors`, {
      params: {
        filters: { slug: { $eq: slug } },
        populate:
          "doctors_detail,profile,treatments,treatments.img,doctors_detail.faqs,doctors_detail.seo,doctors_detail.seo.metaImage,doctors_detail.seo.metaSocial.image",
      },
    });
    return response.data;
  },
);

const initialState = {
  data: null,
  status: "idle",
  error: null,
};

const topDoctorsDetailsSlice = createSlice({
  name: "topDoctors",
  initialState,
  reducers: {
    clearState: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopDoctors.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTopDoctors.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchTopDoctors.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { clearState } = topDoctorsDetailsSlice.actions;

export default topDoctorsDetailsSlice.reducer;
