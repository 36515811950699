import Link from 'next/link';
import './Footer.scss';
import { footerInfo } from '@/utils/data';
import { useIntl } from 'react-intl';
import Image from 'next/image';

export default function Footer({ data }) {
  const intl = useIntl();
  const footerData = data?.data || {};

  return (
    <div className="footerWrapper">
      <div className="footerContainer flex flex-row justify-between">
        <div>
          {footerInfo.map((info, i) => (
            <div
              className="flex flex-col infoContainer"
              key={i}
            >
              <div className="flex flex-col items-center justify-center w-full gap-2 sm:gap-10 sm:justify-between sm:flex-row">
                {info.values?.map((val, j) => {
                  const linkText = intl.formatMessage({
                    id: val.name,
                  });
                  return val.link !== '' ? (
                    <Link
                      className="link"
                      href={val.link}
                      key={j}
                      aria-label={linkText}
                    >
                      <div className="listValues">
                        {linkText}
                      </div>
                    </Link>
                  ) : (
                    <div className="listValues" key={j}>
                      {linkText}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>

        <div className="socialIcons">
          {footerData.social_media_links?.map((social, index) => (
            <a
              key={index}
              href={social.url}
              target="_blank"
              rel="noopener noreferrer"
              className="socialIcon"
              aria-label={`Visit our ${social.platform} page`}
            >
              {social.icon && (
                <Image
                  src={social.icon.url}
                  alt={social.platform}
                  width={24}
                  height={24}
                />
              )}
            </a>
          ))}
        </div>
      </div>

      <div className="company">
        <div className="companyContainer">
          <p className="name">{footerData.company_name}</p>
          <div className="contact-info">
            <a href={`tel:${footerData.contact_number}`} className="contact-link">
              {footerData.contact_number}
            </a>
            <a href={`mailto:${footerData.contact_email}`} className="contact-link">
              {footerData.contact_email}
            </a>
          </div>
        </div>
        <div className="companyContainer">
          <p className="copy">{footerData.footer_text}</p>
        </div>
      </div>
    </div>
  );
}
