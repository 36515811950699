// howItWorksSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const fetchHowItWorks = createAsyncThunk(
  "howItWorks/fetchHowItWorks",
  async (locale = "en") => {
    const response = await axios.get(
      `how-it-works?populate=img&locale=${locale}&sort[1]=id:desc`,
    );
    return response.data;
  },
);

const initialState = {
  items: [],
  status: "idle",
  error: null,
};

const howItWorksSlice = createSlice({
  name: "howItWorks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHowItWorks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchHowItWorks.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
      })
      .addCase(fetchHowItWorks.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default howItWorksSlice.reducer;

export const selectAllHowItWorks = (state) => state.howItWorks.items;
