import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const fetchSuccessStories = createAsyncThunk(
  "successStories/fetchSuccessStories",
  async (locale = "en") => {
    const response = await axios.get(
      `success-stories?populate=*&locale=${locale}`,
    );
    return response.data;
  },
);

const initialState = {
  stories: [],
  status: "idle",
  error: null,
};

const successStoriesSlice = createSlice({
  name: "successStories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuccessStories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSuccessStories.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.stories = action.payload;
      })
      .addCase(fetchSuccessStories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default successStoriesSlice.reducer;

export const selectAllSuccessStories = (state) => state.successStories.stories;
