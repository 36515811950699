"use client";
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCWCr3yzVzo3WYhoW9SycT6-9QfmxTVzIk",
  authDomain: "fitsib-web.firebaseapp.com",
  projectId: "fitsib-web",
  storageBucket: "fitsib-web.appspot.com",
  messagingSenderId: "24715151804",
  appId: "1:24715151804:web:bf1b1fa054f9a3e005c3b9",
  measurementId: "G-2DP0HK90LM",
};

const app = initializeApp(firebaseConfig);
let messaging;
if (typeof window !== "undefined") {
  messaging = getMessaging(app);
}
export { messaging };
