"use client";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "@/utils/axios";

export const fetchTreatments = createAsyncThunk(
  "treatments/fetchTreatments",
  async (locale = "en") => {
    const queryString = `treatments?populate=img&locale=${locale}`;
    const response = await axios.get(queryString);
    return response.data;
  },
);

const treatmentsSlice = createSlice({
  name: "treatments",
  initialState: {
    treatments: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTreatments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTreatments.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.treatments = action.payload;
      })
      .addCase(fetchTreatments.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default treatmentsSlice.reducer;

export const selectAllTreatments = (state) => state.treatments.treatments;
export const selectAllTreatmentsStaus = (state) => state.treatments.status;
